import { createSlice } from '@reduxjs/toolkit';
import storage from '../services/storage';

export const setContent = async content => {
  await storage.set('content', JSON.stringify(content));
};

export const contentSlice = createSlice({
  name: 'content',
  initialState: {
    v: 1,
    content: ''
  },
  reducers: {
    updateContent: (state, action) => {
      state.content = action.payload;
      setContent(state);
    }
  }
});

export const { updateContent } = contentSlice.actions;

export const setDefaultContent = () => dispatch => {
  (async () => {
    // console.log('setDefaultContent', process.env.REACT_APP_WEB);
    // if (!!process.env.REACT_APP_WEB) {
    const contentInStorage = await storage.get('content');

    if (contentInStorage) {
      const contentParsed = JSON.parse(contentInStorage);
      dispatch(updateContent(contentParsed.content));
    }
    // } else {
    // window.ipcRender.send('toMain', { action: 'GET_ITEMS' });
    // window.ipcRender.receive('toUI', data => {
    //   setItems(data.items);
    // });
    // }
  })();
};

export const selectContent = state => state.content.content;

export default contentSlice.reducer;
