import { useState, useEffect, useRef } from 'react';
import {
  MDXEditor,
  headingsPlugin,
  listsPlugin,
  linkPlugin,
  quotePlugin,
  toolbarPlugin,
  directivesPlugin,
  codeBlockPlugin,
  // BlockTypeSelect,
  BoldItalicUnderlineToggles,
  ListsToggle,
  AdmonitionDirectiveDescriptor
} from '@mdxeditor/editor';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateContent,
  selectContent,
  setDefaultContent
} from '../../state/contentSlice';
import './Editor.scss';

const Editor = () => {
  const [value, setValue] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const ref = useRef(null);
  const dispatch = useDispatch();
  const content = useSelector(selectContent);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setIsFirstLoad(false);
      dispatch(updateContent(value));
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [value, dispatch, isFirstLoad]);

  const onChange = e => {
    setValue(e);
  };

  useEffect(() => {
    if (!isLoaded) {
      setIsLoaded(true);
      ref.current?.setMarkdown(content);
    }
  }, [content, isLoaded]);

  useEffect(() => {
    dispatch(setDefaultContent());
  }, [dispatch]);

  return (
    <div className="editor-container">
      <MDXEditor
        ref={ref}
        markdown={''}
        onChange={onChange}
        contentEditableClassName="editor-editable"
        plugins={[
          directivesPlugin({
            directiveDescriptors: [AdmonitionDirectiveDescriptor]
          }),
          codeBlockPlugin({ defaultCodeBlockLanguage: 'js' }),
          headingsPlugin(),
          linkPlugin(),
          listsPlugin(),
          quotePlugin(),
          toolbarPlugin({
            toolbarContents: () => (
              <>
                <BoldItalicUnderlineToggles />
                <ListsToggle />
                {/* <BlockTypeSelect /> */}
                {/* <CodeToggle /> */}
                {/* <InsertAdmonition /> */}
                {/* <InsertTable /> */}
                {/* <InsertCodeBlock /> */}
              </>
            )
          })
        ]}
      />
    </div>
  );
};

export default Editor;
