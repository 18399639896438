import {
  Container,
  Row,
  Col,
  Button,
  ListGroup,
  ListGroupItem
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { updateItems, selectItems } from '../../state/itemsSlice';
import { selectTheme, updateTheme } from '../../state/themeSlice';
import { updateConfig } from '../../state/configSlice';
import THEMES from '../../const/themes';
import Item from '../../components/Item/Item';
import logo from '../../assets/images/logo.svg';
import './Settings.scss';

const Settings = () => {
  const theme = useSelector(selectTheme);
  const itemsCount = useSelector(selectItems).length;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const itemsSample = [
    {
      id: uuid(),
      content: 'Very Important Task!',
      priority: 'p1',
      status: 'todo',
      order: 0
    },
    {
      id: uuid(),
      content: 'Bearly important to remember',
      priority: 'p2',
      status: 'inProgress',
      order: 1
    },
    {
      id: uuid(),
      content: 'Regular task in the list',
      priority: 'p3',
      status: 'done',
      order: 2
    }
  ];
  const setNewTheme = themeName => {
    dispatch(updateTheme(themeName));
    dispatch(updateConfig({ theme: themeName }));
  };

  const removeAll = () => {
    dispatch(updateItems([]));
  };

  // console.log('theme', theme);

  return (
    <div
      style={{
        height: 'calc(100vh - 80px)',
        overflow: 'auto',
        paddingTop: '20px'
      }}
    >
      <Container>
        <Row>
          <Col>
            <br />
            <br />
            <h4>
              <span style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
                <i className="icon-arrow_back" />
                &nbsp; Back
              </span>
            </h4>
            <br />
            <br />
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 align="center">Settings</h2>
            <br />
            <br />
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>
              Theme: <strong>{theme}</strong>
            </h4>

            <br />

            {Object.keys(THEMES).map(themeName => (
              <div key={`theme-sample-container-${themeName}`}>
                <div
                  className="p-3 my-2 rounded"
                  style={{
                    backgroundColor: THEMES[themeName].bgBody,
                    color: THEMES[themeName].colorBody,
                    border: `1px solid ${THEMES[themeName].dark}`
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  >
                    <strong>{themeName}</strong>

                    <Button
                      onClick={() => setNewTheme(themeName)}
                      style={{
                        backgroundColor: THEMES[themeName].bgBody,
                        color: THEMES[themeName].colorBody
                      }}
                    >
                      Select
                    </Button>
                  </div>

                  <br />

                  <ListGroup>
                    <ListGroupItem
                      className="theme-sample-header"
                      style={{
                        backgroundColor: THEMES[themeName].bgNavbar,
                        gridTemplateColumns: 'auto'
                      }}
                    >
                      <img
                        className="logo"
                        src={logo}
                        alt="Notes - Make Your Tasks Done!"
                      />
                    </ListGroupItem>
                  </ListGroup>

                  {itemsSample.map(item => (
                    <Item
                      key={`item-${item.id}-sample-${themeName}`}
                      item={item}
                      theme={themeName}
                      onContentChange={() => {}}
                      onKeyChange={() => {}}
                      setPriority={() => {}}
                      setStatus={() => {}}
                      setDragged={() => {}}
                      setClosestDropZone={() => {}}
                      removeItem={() => {}}
                    />
                  ))}
                </div>
                <br />
              </div>
            ))}
            <br />
            <br />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h4>DANGER!</h4>
            <h5>Remove all items</h5>

            <Button color="danger" onClick={removeAll}>
              <span className="icon-clear"></span> Delete all {itemsCount} items
            </Button>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Settings;
