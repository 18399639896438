import { createSlice } from '@reduxjs/toolkit';

// const setItems = items => {
//   localStorage.setItem('items', JSON.stringify(items));
// };

export const itemsSlice = createSlice({
  name: 'items',
  initialState: {
    list: []
  },
  reducers: {
    addItem: (state, action) => {
      state.list.push(action.payload);
      // setItems(state.list);
    },
    updateItemContent: (state, action) => {
      state.list = [
        ...state.list.map(item => ({
          ...item,
          content:
            item.id === action.payload.id
              ? action.payload.content
              : item.content
        }))
      ];
      // setItems(state.list);
    },
    updateItemStatus: (state, action) => {
      state.list = [
        ...state.list.map(item => ({
          ...item,
          status:
            item.id === action.payload.id ? action.payload.status : item.status
        }))
      ];
      // setItems(state.list);
    },
    updateItemPriority: (state, action) => {
      state.list = [
        ...state.list.map(item => ({
          ...item,
          priority:
            item.id === action.payload.id
              ? action.payload.priority
              : item.priority
        }))
      ];
      // setItems(state.list);
    },
    updateItems: (state, action) => {
      state.list = [...action.payload];
      // setItems(state.list);
    }
  }
});

export const {
  addItem,
  updateItemContent,
  updateItemStatus,
  updateItemPriority,
  updateItems
} = itemsSlice.actions;

export const setDefaultItems = () => dispatch => {
  (async () => {
    if (!!process.env.REACT_APP_WEB) {
      const itemsInStorage = localStorage.getItem('items');
      const itemsParsed = JSON.parse(itemsInStorage);

      if (itemsParsed) {
        dispatch(
          updateItems(
            itemsParsed.map(item => ({
              ...item,
              priority: item.priority || 'p3',
              status: item.status || 'todo',
              order: item.order || 0
            }))
          )
        );
      }
    } else {
      // window.ipcRender.send('toMain', { action: 'GET_ITEMS' });
      // window.ipcRender.receive('toUI', data => {
      //   setItems(data.items);
      // });
    }
  })();
};

export const selectItems = state => state.items.list;

export default itemsSlice.reducer;
