class ChromeStorage {
  constructor() {
    this.storage = window.chrome.storage.local;
  }

  set(name, value) {
    return this.storage.set({ [name]: value }).then(() => {
      return value;
    });
  }

  get(name) {
    return new Promise((resolve, reject) => {
      this.storage
        .get(name)
        .then(result => {
          resolve(result[name]);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  remove(name) {
    return this.storage.remove(name);
  }
}

export default ChromeStorage;
