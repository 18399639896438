const PRIORITIES = {
  p1: {
    title: 'P1',
    style: {
      color: 'var(--bs-danger)'
    },
    icon: 'check_box_outline_blank',
    iconStyle: {
      color: 'var(--bs-danger)'
    }
  },
  p2: {
    title: 'P2',
    style: {
      color: 'var(--bs-warning)'
    },
    icon: 'check_box_outline_blank',
    iconStyle: {
      color: 'var(--bs-warning)'
    }
  },
  p3: {
    title: 'P3',
    style: {
      color: 'var(--bs-dark)'
    },
    icon: 'check_box_outline_blank',
    iconStyle: {
      color: 'var(--bs-dark)'
    }
  },
  p4: {
    title: 'P4',
    style: {
      fontWeight: 'bold',
      fontStyle: 'normal',
      fontSize: '18px',
      color: 'blue'
    }
  },
  p5: {
    title: 'P5',
    style: {
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontSize: '18px',
      color: 'black'
    },
    icon: 'done',
    iconStyle: {
      color: 'black'
    }
  }
};

// const SORTING = ['free', 'priority'];

export default PRIORITIES;
