import { Container, Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        height: 'calc(100vh - 100px)',
        overflow: 'auto',
        padding: '20px',
        backgroundColor: '#ccc',
        borderRadius: '10px',
        margin: '0px 20px'
      }}
    >
      <Container fluid>
        <Row>
          <Col>
            <span
              className="close-button"
              style={{
                cursor: 'pointer',
                position: 'absolute',
                left: '20px'
              }}
              onClick={() => navigate('/')}
            >
              <i className="icon-chevron-thin-left" />
              &nbsp; Back
            </span>
            <h2 align="center">Privacy Policy</h2>
            <br />
            <br />
          </Col>
        </Row>

        {/* <hr /> */}
        <Row>
          <Col>
            <p>
              <b>Effective Date: 13 Jan 2024</b>
            </p>

            <p>
              Welcome to Notes web application, futher Application. Protecting
              your privacy is fundamental to our mission and services. This
              Privacy Policy explains how we handle your information in relation
              to the Application.
            </p>

            <h4>1. Information Collection and Usage</h4>

            <p>
              No Personal Data Collection: The Extension does not collect,
              store, or process any personal data from its users. All
              information (like due dates and times) is stored locally on your
              device and is not accessible to us.
            </p>

            <p>
              No Browser Data Collection: We do not collect any data related to
              your browser usage, such as browsing history, website visits, or
              online activities.
            </p>

            <p>
              No Calendar Sync Data: The Extension does not offer a calendar
              sync feature; hence, it does not access, store, or process any
              calendar-related data.
            </p>

            <h4>2. Data Storage and Protection</h4>

            <p>
              Local Storage: All data (like due dates and times) entered into
              the Application is stored locally on your device. We do not have
              access to this information. More details about Local Storage you
              can find at{' '}
              <a
                href="https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage"
                target="_blank"
                rel="noreferrer"
              >
                MDM Window: localStorage property
              </a>
            </p>

            <p>
              Data Security: As the Application does not collect or store any
              data on external servers, there is no risk of data breaches or
              unauthorized access from our end.
            </p>

            <h4>3. Data Sharing and Disclosure</h4>

            <p>
              No Data Sharing: Since no personal data is collected, there is no
              sharing or disclosure of any user data with third parties.
            </p>

            <h4>4. User Control and Rights</h4>

            <p>
              Local Data Management: You have full control over your data. You
              can add, modify, or delete your content within the Application at
              any time.
            </p>

            <h4>5. Changes to This Privacy Policy</h4>

            <p>
              Updates: Any changes to this Privacy Policy will be communicated
              through an update of the Application and reflected in the policy's
              Effective Date.
            </p>

            <h4>6. Contact Us</h4>

            <p>
              If you have any questions or concerns about this Privacy Policy,
              please contact our support using this email:{' '}
              <a
                href="mailto:support@dit.software?subject=Notes - Question&body=I have a question about ..."
                target="_blank"
                rel="noreferrer"
              >
                support@dit.software
              </a>
              .
            </p>

            <p>
              Please note that this Privacy Policy is specific to Note web
              application and does not apply to any external websites or
              services you may interact with through the Application.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
