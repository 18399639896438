import { Container, Row, Col } from 'reactstrap';

const Credits = () => {
  return (
    <div
      style={{
        height: 'calc(100vh - 100px)',
        overflow: 'auto',
        padding: '20px',
        backgroundColor: '#ccc',
        borderRadius: '10px',
        margin: '0px 20px'
      }}
    >
      <Container fluid>
        <Row>
          <Col>
            <h2 align="center">Credits</h2>
            <br />
            <br />
          </Col>
        </Row>

        {/* <hr /> */}
        <Row>
          <Col>
            <h4>Used software</h4>

            <p>
              <a
                href="https://react.dev/"
                target="_blank"
                rel="noreferrer nofollow"
              >
                ReactJS
              </a>
            </p>

            <p>
              <a
                href="https://mdxeditor.dev/"
                target="_blank"
                rel="noreferrer nofollow"
              >
                MDX Editor
              </a>
            </p>

            <p>
              <a
                href="https://reactrouter.com/"
                target="_blank"
                rel="noreferrer nofollow"
              >
                React Router Dom
              </a>
            </p>

            <p>
              <a
                href="https://redux.js.org/"
                target="_blank"
                rel="noreferrer nofollow"
              >
                Redux
              </a>
            </p>

            <p>
              <a
                href="https://redux-toolkit.js.org/"
                target="_blank"
                rel="noreferrer nofollow"
              >
                Redux Toolkit
              </a>
            </p>

            <p>
              <a
                href="https://react.dev/"
                target="_blank"
                rel="noreferrer nofollow"
              >
                ReactJS
              </a>
            </p>

            <p>
              <a
                href="https://getbootstrap.com/"
                target="_blank"
                rel="noreferrer nofollow"
              >
                Bootstrap
              </a>
            </p>

            <p>
              <a
                href="https://reactstrap.github.io/"
                target="_blank"
                rel="noreferrer nofollow"
              >
                Reactstrap
              </a>
            </p>

            <h4>Contact us</h4>

            <p>
              If you have any questions please contact our support via email:{' '}
              <a
                href="mailto:support@dit.software?subject=Notes - Question&body=I have a question about ..."
                target="_blank"
                rel="noreferrer"
              >
                support@dit.software
              </a>
              .
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Credits;
