const STATUSES = {
  todo: {
    title: 'To Do',
    color: 'warning',
    icon: 'check_box_outline_blank'
  },
  inProgress: {
    title: 'In Progress',
    color: 'primary',
    icon: 'indeterminate_check_box'
  },
  done: {
    title: 'Done',
    color: 'secondary',
    icon: 'check_box'
  }
};

export default STATUSES;
