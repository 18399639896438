import { configureStore, combineReducers } from '@reduxjs/toolkit';
import itemsReducer from './itemsSlice';
import themeReducer from './themeSlice';
import configReducer from './configSlice';
import contentReducer from './contentSlice';

export const store = configureStore({
  reducer: combineReducers({
    items: itemsReducer,
    theme: themeReducer,
    config: configReducer,
    content: contentReducer
  })
});
