import LocalStorage from './local';
import ChromeStorage from './chrome';

class Storage {
  constructor() {
    this.storage =
      window.chrome && window.chrome.storage
        ? new ChromeStorage()
        : new LocalStorage();
  }

  get(name) {
    return this.storage.get(name);
  }

  set(name, value) {
    return this.storage.set(name, value);
  }

  remove(name) {
    return this.storage.remove(name);
  }
}

const storage = new Storage();

export default storage;
