import { createSlice } from '@reduxjs/toolkit';
import { updateItems, setDefaultItems } from './itemsSlice';
import { updateTheme, setDefaultTheme } from './themeSlice';

export const setConfig = config => {
  localStorage.setItem('config', JSON.stringify(config));
};

export const configSlice = createSlice({
  name: 'config',
  initialState: {
    v: 1,
    sort: 'priority',
    isShowDone: false,
    isShowStatuses: false,
    theme: 'default'
  },
  reducers: {
    updateConfig: (state, action) => {
      Object.keys(action.payload).forEach(key => {
        state[key] = action.payload[key];
      });
      setConfig(state);
    }
  }
});

export const { updateConfig } = configSlice.actions;

export const setDefaultConfig = () => dispatch => {
  (async () => {
    if (!!process.env.REACT_APP_WEB) {
      const configInStorage = localStorage.getItem('config');

      if (configInStorage) {
        const configParsed = JSON.parse(configInStorage);

        dispatch(updateConfig(configParsed));

        if (configParsed.items && configParsed.items.length) {
          dispatch(
            updateItems(
              configParsed.items.map(item => ({
                ...item,
                priority: item.priority || 'p3',
                status: item.status || 'todo',
                order: item.order || 0
              }))
            )
          );
        } else {
          dispatch(setDefaultItems());
        }

        if (configParsed.theme) {
          dispatch(updateTheme(configParsed.theme));
        } else {
          dispatch(setDefaultTheme());
        }
      }
    } else {
      // window.ipcRender.send('toMain', { action: 'GET_ITEMS' });
      // window.ipcRender.receive('toUI', data => {
      //   setItems(data.items);
      // });
    }
  })();
};

export const selectConfig = state => state.config;

export default configSlice.reducer;
