class LocalStorage {
  set(name, value) {
    return new Promise((resolve, reject) => {
      localStorage.setItem(name, value);
      resolve(value);
    });
  }

  get(name) {
    return new Promise((resolve, reject) => {
      const value = localStorage.getItem(name);
      resolve(value);
    });
  }

  remove(name) {
    return new Promise((resolve, reject) => {
      localStorage.removeItem(name);
      resolve();
    });
  }
}

export default LocalStorage;
