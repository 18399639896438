import { Button, InputGroup, InputGroupText } from 'reactstrap';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import Markdown from 'react-markdown';
import PRIORITIES from '../../const/priorities';
import STATUSES from '../../const/statuses';
import THEMES from '../../const/themes';
import './Item.scss';

const Item = ({
  item,
  onContentChange,
  onKeyChange,
  onEditItem,
  onSaveItem,
  setStatus,
  restoreItem,
  removeItem,
  isEditable,
  theme
}) => {
  return (
    <>
      <InputGroup
        id={`item-${item.id}`}
        style={{ backgroundColor: THEMES[theme].white }}
      >
        <InputGroupText>
          <Button
            style={{ background: 'none', border: 0 }}
            onClick={() => setStatus(item)}
          >
            <i
              className={`icon-${STATUSES[item.status].icon}`}
              style={{ ...PRIORITIES[item.priority].iconStyle }}
            />
          </Button>
        </InputGroupText>
        {item && isEditable ? (
          <MDEditor
            value={item.content}
            onChange={e => onContentChange(e, item)}
            onKeyDown={onKeyChange}
            previewOptions={{
              rehypePlugins: [[rehypeSanitize]]
            }}
            color="light"
          />
        ) : (
          <div
            className="editor-item-content"
            onClick={() => onEditItem(item)}
            style={{
              ...PRIORITIES[item.priority].style,
              textDecoration: item.status === 'done' ? 'line-through' : 'none'
            }}
          >
            <Markdown>{item.content}</Markdown>
            {/* <MDEditor.Markdown
              source={item.content}
              style={{ whiteSpace: 'pre-wrap' }}
              color="light"
            /> */}
          </div>
        )}
        <InputGroupText className="actions">
          {isEditable ? (
            <span
              className="editor-button"
              onClick={() => onSaveItem(item)}
              style={{ color: THEMES[theme].dark }}
            >
              <i className="icon-save" />
            </span>
          ) : null}

          {/* {item.status === 'todo' ? (
            <span
              className="editor-button"
              onClick={() => setStatus(item, 'inProgress')}
              style={{ color: THEMES[theme].dark }}
            >
              <i className="icon-play_arrow" />
            </span>
          ) : null} */}

          {/* {item.status === 'inProgress' ? (
            <span
              className="editor-button"
              onClick={() => setStatus(item, 'todo')}
              style={{ color: THEMES[theme].dark }}
            >
              <i className="icon-pause" />
            </span>
          ) : null} */}

          {/* {item.status === 'todo' || item.status === 'inProgress' ? (
            <span
              className="editor-button"
              onClick={() => setStatus(item, 'done')}
              style={{ color: THEMES[theme].dark }}
            >
              <i className="icon-stop" />
            </span>
          ) : null} */}

          {item.status === 'done' && (
            <span
              // ref={refRecover}
              className="editor-button"
              onClick={() => restoreItem(item)}
              style={{ color: THEMES[theme].dark }}
            >
              <i className="icon-replay" />
            </span>
          )}

          <span
            className="editor-button"
            onClick={() => removeItem(item)}
            style={{ color: THEMES[theme].dark }}
          >
            <i className="icon-delete" />
          </span>
        </InputGroupText>
      </InputGroup>
    </>
  );
};

// <ContentEditable
//   className="editor-item-content"
//   html={item.content || ' '}
//   // onBlur={handleBlur}
//   onChange={e => onContentChange(e, item)}
//   onKeyDown={onKeyChange}
//   style={{
//     ...PRIORITIES[item.priority].style,
//     textDecoration: item.status === 'done' ? 'line-through' : 'none'
//   }}
// />

export default Item;
