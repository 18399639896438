import { Container } from 'reactstrap';
import Editor from '../../components/Editor/Editor';
import './Home.scss';

const Home = () => {
  return (
    <Container fluid>
      <Editor />
    </Container>
  );
};

export default Home;
