import { useEffect } from 'react';
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation
} from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setTheme, selectTheme } from './state/themeSlice';
import { setDefaultConfig } from './state/configSlice';
import Contacts from './pages/Contacts/Contacts';
import Settings from './pages/Settings/Settings';
import Home from './pages/Home/Home';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import Credits from './pages/Credits/Credits';
import './App.scss';

export default function App() {
  const theme = useSelector(selectTheme);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(setDefaultConfig());
  }, [dispatch]);

  useEffect(() => {
    setTheme(theme);
  }, [theme]);

  useEffect(() => {
    // console.log('location', location.pathname);
  }, [location]);

  return (
    <div className="app" data-color-mode="light">
      <Container className="nav-bar" fluid>
        <Row>
          <Col xs="12">
            <div className="nav-container">
              <div className="nav-logo">
                {location.pathname === '/' ||
                location.pathname === '/index.html' ? null : (
                  <span
                    className="nav-button"
                    style={{
                      cursor: 'pointer',
                      position: 'absolute',
                      left: '20px'
                    }}
                    onClick={() => navigate('/')}
                  >
                    <i className="icon-chevron-thin-left" />
                    &nbsp; Back
                  </span>
                )}

                <Link className="nav-button" to="/">
                  <span className="icon-feather"></span>
                </Link>
                {/*<span className="editor-button">
                <i className="icon-format_italic" />
              </span>
              <span className="editor-button" onClick={onFormatLinethrough}>
                <i className="icon-format_strikethrough" />
              </span> */}
                {/* <span className="editor-button" onClick={onFormatClear}>
                <i className="icon-format_clear" />
              </span> */}
              </div>
              {/* <div className="nav-controls">
                <Link className="nav-button" to="/">
                  <i className="icon-list" />
                </Link>

                <Link className="nav-button" to="/settings">
                  <i className="icon-settings" />
                </Link>
              </div> */}
            </div>
          </Col>
          {/* <Col xs="6">
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                height: '100%'
              }}
            >
              <Link className="nav-button" to="/">
                <i className="icon-home" />
              </Link>

              <Link className="nav-button" to="/settings">
                <i className="icon-settings" />
              </Link>

              <Link className="nav-button" to="/contacts">
                <i className="icon-email" />
              </Link>
            </div>
          </Col> */}
        </Row>
        <div className="footer">
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/credits" className="version">
            v1.0.1
          </Link>
        </div>
      </Container>

      <Routes>
        <Route path="/settings" element={<Settings />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/credits" element={<Credits />} />
        <Route path="/index.html" element={<Home />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </div>
  );
}
