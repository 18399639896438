const THEMES = {
  default: {
    primary: '#00f',
    secondary: '#999',
    success: '#090',
    info: '#99f',
    warning: '#fb0',
    danger: '#f00',
    light: '#eee',
    white: '#fff',
    dark: '#333',
    black: '#000',
    bgBody: '#eee',
    bgNavbar: '#fff',
    colorBody: '#333',
    colorNavbar: '#333'
  },
  dark: {
    primary: '#007',
    secondary: '#444',
    success: '#060',
    info: '#00a',
    warning: '#cc0',
    danger: '#f44',
    light: '#333',
    white: '#000',
    dark: '#eee',
    black: '#fff',
    bgBody: '#333',
    bgNavbar: '#111',
    colorBody: '#eee',
    colorNavbar: '#eee'
  },
  bright: {
    primary: '#99f',
    secondary: '#ccc',
    success: '#0c0',
    info: '#ccf',
    warning: '#cc0',
    danger: '#f00',
    light: '#fff',
    white: '#fff',
    dark: '#333',
    black: '#000',
    bgBody: '#eee',
    bgNavbar: '#0f0',
    colorBody: '#333',
    colorNavbar: '#333'
  },
  universe: {
    primary: '#99f',
    secondary: '#ccc',
    success: '#0c0',
    info: '#ccf',
    warning: '#cc0',
    danger: '#f00',
    light: '#fff',
    white: '#fff',
    dark: '#333',
    black: '#000',
    bgBody: '#eee',
    bgNavbar: '#00f',
    colorBody: '#333',
    colorNavbar: '#eee'
  },
  magenta: {
    primary: '#00f',
    secondary: '#999',
    success: '#090',
    info: '#99f',
    warning: '#fb0',
    danger: '#f00',
    light: '#eee',
    white: '#fff',
    dark: '#333',
    black: '#000',
    bgBody: '#D84797',
    bgNavbar: '#820933',
    colorBody: '#333',
    colorNavbar: '#eee'
  }
};

export default THEMES;
