import { createSlice } from '@reduxjs/toolkit';
import THEMES from '../const/themes';

export const setTheme = themeName => {
  const theme = THEMES[themeName || 'light'];
  document.documentElement.style.setProperty('--bs-primary', theme.primary);
  document.documentElement.style.setProperty('--bs-secondary', theme.secondary);
  document.documentElement.style.setProperty('--bs-success', theme.success);
  document.documentElement.style.setProperty('--bs-info', theme.info);
  document.documentElement.style.setProperty('--bs-warning', theme.warning);
  document.documentElement.style.setProperty('--bs-danger', theme.danger);
  document.documentElement.style.setProperty('--bs-white', theme.white);
  document.documentElement.style.setProperty('--bs-black', theme.black);
  document.documentElement.style.setProperty('--bs-light', theme.light);
  document.documentElement.style.setProperty('--bs-dark', theme.dark);
  document.documentElement.style.setProperty('--bs-bg-body', theme.bgBody);
  document.documentElement.style.setProperty('--bs-bg-navbar', theme.bgNavbar);
  document.documentElement.style.setProperty(
    '--bs-color-body',
    theme.colorBody
  );
  document.documentElement.style.setProperty(
    '--bs-color-navbar',
    theme.colorNavbar
  );
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    value: 'default'
  },
  reducers: {
    setDefaultTheme: state => {
      state.value = 'default';
    },
    updateTheme: (state, action) => {
      state.value = action.payload;
    }
  }
});

export const { setDefaultTheme, updateTheme } = themeSlice.actions;

// export const incrementAsync = amount => dispatch => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount));
//   }, 1000);
// };

export const selectTheme = state => state.theme.value;

export default themeSlice.reducer;
