import { Container, Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

const Contacts = () => {
  const navigate = useNavigate();

  return (
    <Container
      style={{
        height: 'calc(100vh - 80px)',
        overflow: 'auto',
        paddingTop: '20px'
      }}
    >
      <Row>
        <Col>
          <br />
          <br />
          <h4>
            <span style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
              <i className="icon-arrow_back" />
              &nbsp; Back
            </span>
          </h4>
          <br />
          <br />
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 align="center">Contact us</h2>
          <br />
          <br />
        </Col>
      </Row>
      <Row>
        <Col>
          <p align="center">
            <a href="mailto:info@dit.software?subject=[NOTES] Question">
              info@dit.software
            </a>
          </p>
          <br />
          <br />
        </Col>
      </Row>
    </Container>
  );
};

export default Contacts;
